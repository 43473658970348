import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


interface Props {
    speed?: string,
    space?: number
}

const Spinner: React.FC<Props> = ({ 
    speed = '1.5s',
    space = 10
}) => {
    return (
        <Content speed={speed} space={space}>
            <FontAwesomeIcon icon={faSpinner} />
        </Content>
    )
}

interface StyleProps {
    speed: string,
    space: number
}
  

const Content = styled.div<StyleProps> `
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    display: inline-block;
    padding-right: ${props => props.space}px;

    svg {
        animation: rotation ${props => props.speed} infinite linear;
    }

`

export default Spinner