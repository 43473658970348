import React, { Component, useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { MobileStyles } from '../../../contexts/Theme/Mixins'

interface Props {
    phase: number,
    paused: boolean
}

const PhaseTimeline: React.FC<Props> = ({ phase, paused  }) => {
    const [activePhase, setPhase] = useState<number>(phase)

    useEffect(() => {
        if ( paused ) {
            setPhase(-1)
        } else {
            setPhase(phase)
        }
    }, [phase, paused])


    return (  
    <Content>
        {/* { phase == 0 &&
            <Inner>
                <Locked>
                    Phase 1 hasn't started
                </Locked>
            </Inner>
        } */}
        <Inner>
            <Bar1 className={(activePhase >= 2) ? 'active' : ''} />
            <Bar2 className={((activePhase == 3) || (activePhase == 4)) ? 'active' : ''} />
            <Bar3 className={(activePhase == 4) ? 'active' : ''} />

            <Phases>
                <Phase className={(activePhase >= 1) ? 'active' : ''}>
                    <Circle className='dot'  />
                    <h3>Phase 1</h3>
                    <div className='desc'>
                        Whitelisters Only
                    </div>
                    <div className='desc'>
                        One Felon
                    </div>
                </Phase>
                <Phase className={(activePhase >= 2) ? 'active' : ''}>
                    <Circle className='dot' />
                    <h3>Phase 2</h3>
                    <div className='desc'>
                        Whitelisters Only
                    </div>
                    <div className='desc'>
                        Up to four Felons
                    </div>
                </Phase>
                <Phase className={(activePhase >= 3) ? 'active' : ''}>
                    <Circle className='dot' />
                    <h3>Phase 3</h3>
                    <div className='desc'>
                        Public
                    </div>
                    <div className='desc'>
                        Two Felons
                    </div>
                </Phase>
                <Phase className={(activePhase == 4) ? 'active' : ''}>
                    <Circle className='dot' />
                    <h3>Phase 4</h3>
                    <div className='desc'>
                        Closed
                    </div>
                    <div className='desc'>
                        Minting Ended
                    </div>
                </Phase>
            </Phases>
        </Inner>
    </Content>
    );
};

export default PhaseTimeline;

const Content = styled.div `
    width: 100%;
`
const Inner = styled.div `
    position: relative;
`
const Locked = styled.div `
    text-align: center;
    font-weight: bold;
    margin-bottom: 25px;
`
const Phases = styled.div `
    display: flex;
    
    ${MobileStyles(`
        display: block;
    `)};
`
const Phase = styled.div `
    width: 25%;
    text-align: center;
    z-index: 3;

    h3 {
        margin: 10px 0px 15px 0px;
        text-transform: uppercase;
        font-size: 14px;
    }

    .dot {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: rgba(255,255,255,.2);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 3px solid rgba(255,255,255,.2);
    }

    &.active {
        .dot {
            background: #24a9a3;
            border-color: #fff;
        }
    }

    .desc {
        margin-bottom: 3px;
    }

    ${MobileStyles(`
        width: auto;
        margin-bottom: 25px;
        border-top: 1px solid rgba(255,255,255,.2);
        padding-top: 25px;
    `)};
`
const Bar1 = styled.div `
    position: absolute;
    height: 5px;
    top: 12px;
    left: 15%;
    right: 65%;  
    background: rgba(255,255,255,.2);
    z-index: 0;
    &.active {
        background: #fff;
    }
    ${MobileStyles(`
        display: none;
    `)};
`
const Bar2 = styled.div `
    position: absolute;
    height: 5px;
    top: 12px;
    left: 40%;
    right: 40%;
    background: rgba(255,255,255,.2);
    z-index: 0;
    &.active {
        background: #fff;
    }
    ${MobileStyles(`
        display: none;
    `)};
`
const Bar3 = styled.div `
    position: absolute;
    height: 5px;
    top: 12px;
    left: 65%;
    right: 15%;
    background: rgba(255,255,255,.2);
    z-index: 0;
    &.active {
        background: #fff;
    }
    ${MobileStyles(`
        display: none;
    `)};
`
const Circle = styled.div `

`
