import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import SectionHeader from './SectionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import Link from '../../../components/Link';
import { GlassBackground } from '../../../contexts/Theme/Mixins'
import { MobileStyles } from '../../../contexts/Theme/Mixins'


// Images
import Profile from '../../../assets/profile.png';
import Spacer from '../../../components/Spacer';

import Teammate01 from '../../../assets/teammate01.jpg';
import Teammate02 from '../../../assets/teammate02.jpg';
import Teammate03 from '../../../assets/teammate03.jpg';
import Teammate04 from '../../../assets/teammate04.jpg';
import Teammate05 from '../../../assets/teammate05.jpg';

const Fade = require('react-reveal/Fade');

const Team: React.FC = ({  }) => {

  return (  
    <Content id='team'>
        <Spacer size='lg' />

        <Fade bottom>
            <SectionHeader title='Fuzzy Felons Team' subtitle="Captives of our own identity" />
        </Fade>
        
        <Spacer size='lg' />

        <Members>
            <Fade left>
                <Member name='ColourBleed'
                        role='Art Director & Creator' 
                        img={Teammate01}
                        twitter='https://twitter.com/colourbleed' />
            </Fade>
            <Fade bottom>
                <Member name='MonkeyButt' 
                        role='Lead Developer'
                        img={Teammate02} />
            </Fade>
            
        </Members>

        <Members className='bottom'>
            <Fade right>
                <Member name='Ctown' 
                        role='Web3 Developer' 
                        img={Teammate04}
                        twitter='https://twitter.com/ctown4v' />
            </Fade>

            <Fade left>
                <Member name='Itjakub' 
                        role='Discord Manager' 
                        img={Teammate03}
                        twitter='https://twitter.com/itjakub' />
            </Fade>
        </Members>

        <Spacer size='lg' />
    </Content>
  );
};

export default Team;

const Content = styled.div `
    width: 100%;
`
const Members = styled.div `
    display: flex;
    justify-content: center;
    &.bottom {
        margin-top: 40px; 
    }
    ${MobileStyles(`
        display: block;
        justify-content: inherit;
        &.bottom{
            margin-top: 0px;
        }
    `)};
`

// =============================================================
//   Member
// =============================================================


interface MemberProps {
    name: string,
    role: string,
    img: string,
    twitter?: string
}

const Member: React.FC<MemberProps> = ({ 
    name,
    role,
    img,
    twitter=''
}) => {
    return (  
    <MemberContent>
        <MemberInner>
            <MemberPadding>
                {/* <div className='img'>
                    <BoxShadow/>
                    <img src={img} />
                </div> */}
                <div className='info'>
                    <div className='name'>{name}</div>
                    <div className='role'>{role}</div>
                    <div className='media'>
                        { twitter && 
                            <Link href={twitter}>
                                <FontAwesomeIcon icon={faTwitter} size='1x' />
                            </Link>
                        }
                    </div>
                </div>
            </MemberPadding>
        </MemberInner>
    </MemberContent>
    );
};

const BoxShadow = styled.div `
    -moz-box-shadow:    inset 0 0 10px rgba(0,0,0,.5);
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,.5);
    box-shadow:         inset 0 0 10px rgba(0,0,0,.5);
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
`



const MemberPadding = styled.div `
    border-radius: 20px;
    padding: 20px 20px 0px 20px;
    margin: 0px 10px;
    ${GlassBackground()};
    width: 185px;
    height: 100%;

    .img {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        height: 200px;
        img {
            height: 200%;
            position: relative;
            left: -110px;
        }
    }

    ${MobileStyles(`
        min-height: 120px;
        height: auto;
        width: auto;

        display: flex;
        > .info {
            flex: 1;
            margin-top: -15px;
        }

        .img {
            margin-right: 10px;
            width: 100px;
            height: 100px;
            img {
                left: -50px;
            }
        }
    `)};
`

const MemberContent = styled.div `
    height: 100%;

    text-align: center;

    &:first-child {
        margin-left: 0px;
    }
    &:last-child {
        margin-right: 0px;
    }
    .name {
        font-weight: bold;
        font-size: 24px;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .role {
        font-size: 16px;
    }

    .media {
        margin-top: 15px;
    }

    ${MobileStyles(`
        margin-bottom: 15px;
        text-align: left;
        .name {
            font-size: 20px;
        }
        .role {
            font-size: 16px;
        }
        img {
            height: 100px;
        }
    `)};
    
`

const MemberInner = styled.div `
height: 100%;
`
