import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Spacer from '../../../components/Spacer';
import Logo from '../../../components/Logo';
import { MobileStyles } from '../../../contexts/Theme/Mixins'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';




// Images
import ImageLogo from '../../../assets/logo_v03.png';
import StandingFelon from '../../../assets/standingFelon_v05.png';
import Button from '../../../components/Button';
import { faShirt, faShoppingBasket, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

//const Zoom = require('react-reveal/Zoom');
const Fade = require('react-reveal/Fade');

const Soon: React.FC = ({  }) => {

  return (  
    <Content>
        <Sides className='sides'>
            <Left className='left'>
                <Fade left duration={500}>
                    <img src={StandingFelon} />
                </Fade>
            </Left>
            <Right className='right'>
                <Fade right duration={500}>
                    <div>
                    <Inner>
                        <div className='mobile'>
                            <img src={ImageLogo} />
                        </div>

                        <Logo size={300} />
                        <Spacer size='sm' />

                        <Fade bottom delay={500}>
                            <Spacer size='md'/>

                            

                            <Paragraph>
                                3,333 Fuzzy Felons have broken loose! Congrats to all of our Felons. Join the Discord and follow us to keep up with what's happening next.
                            </Paragraph>

                            <Spacer size='md'/>
                            
                            <Buttons>
                                <Button href='http://discord.gg/fuzzyfelons' size='md'>
                                    <FontAwesomeIcon icon={faDiscord} /> <Spacer size='sm' direction='vt' /> Join our Discord
                                </Button>
                                <Button href='https://twitter.com/fuzzyfelons' size='md'>
                                    <FontAwesomeIcon icon={faTwitter} /> <Spacer size='sm' direction='vt' /> Follow us
                                </Button>
                                <Button href='https://fuzzyfelons.shop' size='md'>
                                    <FontAwesomeIcon icon={faShoppingCart} /> <Spacer size='sm' direction='vt' /> Shop
                                </Button>
                            </Buttons>
                        </Fade>
                    </Inner>
                    </div>
                </Fade>
            </Right>
        </Sides>

        
    </Content>
  );
};

export default Soon;


const Live = styled.div `
    width: 300px;
    margin: 0px auto;
    margin-bottom: 35px;
    text-align: center;

    .big {
        font-size: 36px;
        a {
            padding: 20px;
            background: rgb(97,83,167);
            
            background: rgb(36,169,162);
            background: linear-gradient(180deg, rgba(36,169,162,1) 0%, rgba(25,140,134,1) 100%);
            &:hover {
                background: rgb(39,182,175);
                background: linear-gradient(180deg, rgba(39,182,175,1) 0%, rgba(27,152,145,1) 100%); 
            }
        }
    }
    .btn, a {
        width: 100%;
    }
    ${MobileStyles(`
        width: 200px;
        .big {
            font-size: 18px;
            a {
                padding: 15px;
            }
        }
    `)};
    
`

const Content = styled.div `
    max-width: 1000px;  
    margin: 0px auto;
    position: absolute;

    .mobile {
        display: none;
        img {
            max-width: 100%;
        }
        position: relative;
        top: -25px;
    }

    position: relative;
    top: 50px;

    h3 {
        font-size: 150%;
        text-align: center;
        margin-bottom: 20px;
    }
    .left {
        img {
            height: 600px;
        }
    }

    ${MobileStyles(`
        text-align: center;

        .sides {
            display: block;
        }

        h3 {
            font-size: 115%;
        }
        .fuzzyfont {
            font-size: 55px;
        }

        .right {
            > div {
                padding: 20px;
            }
        }

        .logo {
            display: none;
        }
        .mobile {
            display: block;
        }

        .left {
            img {
                display: none;
                top: 0px;
                left: 0px;
            }
        }
    `)};

`
const Sides = styled.div `
    display: flex;
`

const Left = styled.div `
    width: 30%;
    img {
        position: relative;
        top: 10px;
        left: 0px;
    }
`
const Right = styled.div `
    flex: 1;
`

const Inner = styled.div `
    position: relative;
    padding: 50px 75px 75px 75px;
    
    ${MobileStyles(`
        padding: 0px 25px;
    `)};
`

const Buttons = styled.div `
    .btn {
        margin-right: 10px;
    }
    .btn:last-child {
        margin-right: 0px;
    }
    ${MobileStyles(`
        margin: 25px 0px;

        width: 100%;
        .btn {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 10px;
        }
    `)};
`

const Paragraph = styled.div `
    font-size: 20px;
`
