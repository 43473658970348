import React, { useCallback, useEffect, useState, useContext, useRef, createRef } from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Modal, { ModalProps } from '../../../components/Modal';
import ModalActions from '../../../components/ModalActions';
import ModalTitle from '../../../components/ModalTitle';
import { ThemeContext, ThemeContextProps, themes } from '../../../contexts/Theme/Theme';
import Spacer from '../../../components/Spacer';

interface Props extends ModalProps {
    onConfirm: (data:any) => void,
    onDismiss: () => void
}

const MintSuccessModal: React.FC<Props> = ({
    onConfirm,
    onDismiss 
}) => {
    const { theme } = useContext(ThemeContext) as ThemeContextProps;
    
    const handleConfirm = useCallback(() => {
        onConfirm({})
        onDismiss()
    }, [onConfirm, onDismiss]);

    const handleDismiss = useCallback(() => onDismiss(), [onDismiss]);

    
    return (
    <Modal >
        <ModalTitle text='Success!' />

        <Message>
            Please note that it may take a moment for your Felon to show up in the marketplaces. 
        </Message>

        <ModalActions>
            <Button size='md' text="Close" background='secondary' onClick={handleDismiss} />    
            {/* <Button size='md' text="Open Sea" background='secondary' onClick={handleConfirm}/> */}
        </ModalActions>

    </Modal>
    )
}

const Message = styled.div `
    line-height: 26px;
`

export default MintSuccessModal