import React from 'react'
import styled from 'styled-components'

import Nav from './components/Nav'

const Footer: React.FC = () => (
  <StyledFooter>
    <StyledFooterInner>
      {/* <Nav /> */}
      Copyright © {new Date().getFullYear()} Fuzzy Felons 
    </StyledFooterInner>
  </StyledFooter>
)

const StyledFooter = styled.footer`
    width: 100%;
    //background: rgb(97,83,167);
    background: rgba(97,83,167, 0.4);
    background: linear-gradient(180deg, rgba(97,83,167,0.4) 0%, rgba(65,52,129,0.4) 100%);   
    text-align: center;
    position: absolute;
    bottom: 0px;
`
const StyledFooterInner = styled.div`
    padding: 50px 20px;
`

export default Footer