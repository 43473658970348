import React from 'react'
import styled from 'styled-components'

interface ModalTitleProps {
  text?: string,
  alignTitle?: string
}

const ModalTitle: React.FC<ModalTitleProps> = ({ text, alignTitle='center' }) => (
  <StyledModalTitle className={alignTitle}>
    {text}
  </StyledModalTitle>
)

const StyledModalTitle = styled.div` 
align-items: center;  
  display: flex;
  font-size: 21px;
  font-weight: 700;
  height: ${props => props.theme.topBar.height}px;
  
  margin-top: ${props => -props.theme.spacing[4]}px;
  color: ${(props) => props.theme.text.primary.color};
  
  &.center {
    justify-content: center;
  }
`

export default ModalTitle