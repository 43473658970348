import React, { useContext } from 'react'
import styled from 'styled-components'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import Footer from '../Footer'
import TopBar from '../TopBar'
import NavBar from '../NavBar'
import { AccountContext } from '../../contexts/Account/AccountContext';

type PageProps = {
  className?: string,
  topBar?: string,
  children?: React.ReactNode,
  footer?: boolean
};

const Page: React.FC<PageProps> = ({ 
  className = 'main', 
  children,
  footer=true
}) => {
  const { accountUser } = useContext(AccountContext)
  return (
    <>
    <Main className={className}>
            {/* <NavBar/> */}
            <Background className='background' />

            <Content className='styledMain'>
                { children }
            {/* { accountUser ? (
                <>
                {children}
                </>
            ) : (
                <>
                <div>Connect your Wallet</div> 
                </>
            )}      */}
            </Content>

            { footer &&
                <Footer />
            }
        </Main>
    </>
  )
}
export default Page

type CenterProps = {
    className?: string,
    children?: React.ReactNode,
}

export const Center: React.FC<CenterProps> = ({ 
    className = 'center', 
    children 
}) => {
    return (
        <CenterColumn className={className}>
            <CenterInner>
                {children}
            </CenterInner>
        </CenterColumn>
    )
}


const Background = styled.div `
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    //background-color: ${props => props.theme.page.background};
    z-index: -1;
    /* Created with https://www.css-gradient.com */
    background: #422c7f;
    background: -webkit-radial-gradient(center, #228ba5, #422c7f);
    background: -moz-radial-gradient(center, #228ba5, #422c7f);
    background: radial-gradient(ellipse at center, #228ba5, #422c7f);
`

const Main = styled.div`
  position: relative;
  overflow: hidden;
  //padding-top: ${props => props.theme.topBar.height}px;
  color: ${props => props.theme.text.primary.color};
  padding-bottom: 120px;
`

const Content = styled.div`
    z-index: 5;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - ${props => props.theme.topBar.height * 2}px);  
`


const CenterColumn = styled.div `
    max-width: 800px;
    width: 100%;
`

const CenterInner = styled.div `
    margin: 20px;
`