import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Logo from '../../components/Logo'
import Card from '../../components/Card';
import Page from '../../components/Page';
import Spacer from "../../components/Spacer";
import Button from '../../components/Button';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from '../../components/Link';
import { faBan, faCheck, faCircle, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import useModal from '../../hooks/useModal';
import Form from './components/MintForm';
import PhaseTimeline from './components/PhaseTimeline';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import OpenSeaLogo from '../../assets/opensea.svg';
import { FuzzyContract, getWhiteList, getOpenseaUrl } from './functions';
import MintError from './components/MintError';
import config from '../../config'
import MintSuccessModal from './components/MintSuccessModal';
import { MobileStyles } from '../../contexts/Theme/Mixins'

const InfuraID = 'ef0e5accf1ae493697f3ed53325b4c25'

const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: InfuraID // required
      }
    },
    walletlink: {
        package: WalletLink, // Required
        options: {
          appName: "Fuzzy Felons", // Required
          infuraId: InfuraID, // Required unless you provide a JSON RPC url; see `rpc` below
          rpc: "", // Optional if `infuraId` is provided; otherwise it's required
          chainId: config.chainId, // Optional. It defaults to 1 if not provided
          appLogoUrl: 'https://fuzzyfelons.com/logo_square.png', // Optional. Application logo image URL. favicon is used if unspecified
          darkMode: false // Optional. Use dark theme, defaults to false
        }
    }
}

const web3Modal = new Web3Modal({
    //network: "",// "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
});

function initWeb3(provider: any) {
    const web3: any = new Web3(provider);
  
    web3.eth.extend({
        methods: [{
            name: "chainId",
            call: "eth_chainId",
            outputFormatter: web3.utils.hexToNumber
        }]
    })
  
    return web3
}

interface walletStateProps {
    address: string,
    balance: string,
    web3: any,
    provider: any,
    connected: boolean
}

const web3InitialState:walletStateProps = {  
    address: "",
    balance: '',
    web3: null,
    provider: null,
    connected: false
}

interface networkStateProps {
    chainId: number,
    networkId: number,
}

const networkState:networkStateProps = {  
    chainId: config.chainId,
    networkId: config.chainId,
}

const Mint: React.FC = ({  }) => {
    //var Contract:any = null
    var wallet = web3InitialState
    //var Contract:any = null
    //const [loaded, setLoaded] = useState<boolean>(false)
    //const [wallet, setWallet] = useState<walletStateProps>(web3InitialState) 
     
    const [Contract, setContract] = useState<any>(null)
    const [openseaUrl, setOpenSeaUrl] = useState<string>('')
    
    // Contract state
    const [chainId, setChainId] = useState<number>(config.chainId)
    const [phase, setPhase] = useState<number>(-1)
    const [paused, setPaused] = useState<boolean>(false)
    const [totalSupply, setTotalSupply] = useState<number | string>('-')
    
    // Account state
    const [address, setAddress] = useState<string>('')  
    const [balance, setBalance] = useState<string>('')  
    const [isWhitelisted, setIsWhiteListed] = useState<boolean>(false)
    const [tokenBalance, setTokenBalance] = useState<number>(0)

    const whitelist = getWhiteList()

    useEffect( () => {
        document.title = "Mint Fuzzy Felons"
        if ( web3Modal.cachedProvider) {
            onConnect();
        }
    }, [])
    
    useEffect(() => {
        refreshUI()
        getAccountAssets()
    }, [address, chainId])

    const onConnect = async () => {
        const provider = await web3Modal.connect()

        const fuzz = new FuzzyContract(provider)
        setContract(fuzz)

        await subscribeProvider(provider);
    
        await provider.enable();
        const web3: any = initWeb3(provider);
    
        const accounts = await web3.eth.getAccounts();
    
        const address = accounts[0];
    
        const networkId = await web3.eth.net.getId();
    
        const chainId = await web3.eth.chainId();

        wallet.address = address
        wallet.web3 = web3
        wallet.provider = provider
        wallet.connected = true

        // Update vars
        setAddress(address)
        setChainId(chainId)
    };

    // ------------------------
    const showAccount = () =>{
        console.log({
            address: address,
            chainId: chainId,
            phase: phase,
            balance: balance,
            whitelist: isWhitelisted,
            tokenBalance: tokenBalance,
            contract: Contract
        })
    }
    // ------------------------

    const subscribeProvider = async (provider: any) => {
        if (!provider.on) {
          return;
        }
        provider.on("close", () => resetApp());

        provider.on("accountsChanged", async (accounts: string[]) => {
            setAddress(accounts[0])
        });

        provider.on("chainChanged", async (chainId: number) => {
            setChainId(chainId)
        })
    }

    const getAccountAssets = async () => {
        if ( !address || !wallet.web3 ) {
            return
        } 

        try {
            wallet.web3.eth.getBalance(address).then((amount:any) => {
                setBalance(amount)
            })
        } catch (error) {
            console.error(error); // tslint:disable-line
        }
    }

    const onSubmit = async () => {
        await getAccountAssets()
        await refreshUI()
        return true
    }

    const onSuccess = (data:any) => {
        onPresentSuccess()
        refreshUI()
    }

    const resetApp = async () => {
        const { web3 } = wallet

        setAddress('')
        setBalance('')
        setIsWhiteListed(false)
        setTokenBalance(0)

        if (web3 && web3.currentProvider && web3.currentProvider.close) {
            await web3.currentProvider.close();
        }
        await web3Modal.clearCachedProvider();
        wallet = web3InitialState
    }

    const refreshUI = async () => {
        if ( !Contract) return

        setPhase(await Contract.getPhase())
        setPaused(await Contract.getPaused())
        setTotalSupply(await Contract.getTotalSupply())

        if ( address ) {
            const balance = await Contract.getBalance(address)
            setTokenBalance(balance)
            
            if ( balance > 0 ) {
                setOpenSeaUrl(getOpenseaUrl(address))
            } else {
                setOpenSeaUrl('')
            }

            if ( whitelist.indexOf(address.toLowerCase()) > -1 ) {
                setIsWhiteListed(true)
            } else {
                setIsWhiteListed(false)
            }
        }
    }

    const [onPresentSuccess, onDismissSuccess] = useModal(
        <MintSuccessModal 
            onConfirm={(data:any) => {
                onDismissSuccess()
            }}
            onDismiss={() => {
            
            }}
        />
    )

    return (
    <Page>  
        <StyledTopBar>
            <Nav>
                <NavLeft>
                    <Logo />
                </NavLeft>
                <NavRight>
                    { address ? (
                        <>
                            <Disconnect onClick={resetApp}>Disconnect</Disconnect>
                            <Account onClick={resetApp}>
                                <FontAwesomeIcon icon={faCircle} size='sm' /> <span>{address}</span>
                            </Account>
                            { isWhitelisted ? (
                                <Whitelist>
                                    <FontAwesomeIcon icon={faCheck} size='1x' /> &nbsp; Whitelisted
                                </Whitelist>
                            ) : (
                                <Whitelist>
                                    <FontAwesomeIcon icon={faBan} size='1x' /> &nbsp; Not Whitelisted
                                </Whitelist>
                            )}
                            <Tokens className={ openseaUrl ? 'link' : ''}>
                                { openseaUrl ? (
                                    <a href={openseaUrl} target='_blank'>
                                        <img src={OpenSeaLogo} />
                                        { tokenBalance == 1 ? (
                                            <>{ tokenBalance } Felon</>
                                        ):(
                                            <>{ tokenBalance } Felons</>
                                        )}
                                    </a>
                                ):(
                                    <>
                                        { tokenBalance == 1 ? (
                                        <>{ tokenBalance } Felon</>
                                    ):(
                                        <>{ tokenBalance } Felons</>
                                    )}
                                    </>
                                )}
                            </Tokens>
                        </>
                    ) : (
                        <>
                            <Link href='http://discord.gg/fuzzyfelons'>
                                <FontAwesomeIcon icon={faDiscord} size='1x' />
                            </Link>
                            <Link href='https://twitter.com/fuzzyfelons'>
                                <FontAwesomeIcon icon={faTwitter} size='1x' />
                            </Link>
                        </>
                    ) }
                </NavRight>
            </Nav>
            

        </StyledTopBar> 

        <Content>

            { address &&
                <Disconnect className='mobileOnly' onClick={resetApp}>Disconnect</Disconnect>
            }

            <Center>
                <Spacer size='lg' /> 
                { ( chainId != config.chainId) ? (
                    <>
                        { config.chainId == 4 &&
                            <Card>
                                <MintError message="Please switch to the Rinkeby Network." />
                            </Card>
                        }
                        { config.chainId == 1 &&
                            <Card>
                                <MintError message="Please switch to Mainnet Network." />
                            </Card>
                        }
                        <Spacer size='lg' />
                    </>       
                ) : (
                    <>
                        { paused ? (
                            <>
                                <h2 className='fuzzyfont'>Minting is Paused</h2>
                                <Spacer size='lg' />
                                <Card>
                                    <MintError message="Minting is temporarily paused. Check out our discord for more information." />
                                </Card>
                            </>
                        ):(
                            <>
                                <h2 className='fuzzyfont'>
                                    { phase == -1 &&
                                        <>Let's break out some Felons!</>
                                    }
                                    { phase == 0 &&
                                        <>Minting Coming Soon!</>
                                    }
                                    { ( phase >= 1 && phase <= 3)  &&
                                        <>Let's break out some Felons!</>
                                    }
                                    { phase == 4 &&
                                        <>Minting Ended</>
                                    }
                                </h2>
                                <h3 className='supply'>
                                    { totalSupply } / 3333
                                </h3>

                                { address && phase == 0 &&
                                    <Whitelisted>
                                    { isWhitelisted ? (
                                        <>
                                            <FontAwesomeIcon icon={faCheck} size='1x' /> 
                                            <div className='msg2'>
                                                <span>{address}</span>is Whitelisted
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faBan} size='1x' /> 
                                            <div className='msg2'>
                                                <span>{address}</span>is not Whitelisted
                                            </div>
                                        </>
                                    )}
                                    </Whitelisted>
                                }

                                <Spacer size='lg' />
                        
                                { !address &&
                                    <Card className='connectCard'>
                                        <Button background='secondary' text='Connect Wallet' onClick={onConnect} />
                                    </Card>
                                }

                                { phase === 0 &&
                                    <Card>
                                        <MintError message="Minting hasn't begun. Please return during an active phase." />
                                    </Card>
                                }  
        
                                { (phase >= 1 && phase <= 3) &&
                                    <>
                                    { address &&
                                        <Card>
                                            <Form 
                                                Contract={Contract}
                                                phase={phase} 
                                                account={address} 
                                                balance={balance}
                                                tokens={tokenBalance}
                                                onSubmit={onSubmit}
                                                onSuccess={onSuccess}/>
                                        </Card>
                                    }
                                    </>
                                }
                                
                                { phase === 4 &&
                                    <Card>
                                        <MintError message="Minting is closed." />
                                    </Card>
                                }
                            </>
                        )}
                    </>
                )}   
            </Center>

            <Spacer size='xl' />          
            <Timeline>
                <PhaseTimeline phase={phase} paused={paused}/>
            </Timeline>       
            <Spacer size='xl' />

            <SmartContract>
                <h3>Smart Contract</h3>
                <a target='_blank' href='https://etherscan.io/address/0x5759110402AA65241FD03435646aFD01a91Ab948#code'>
                    0x5759110402AA65241FD03435646aFD01a91Ab948 <FontAwesomeIcon icon={faExternalLink} size='1x' />
                </a>
            </SmartContract>
            <Spacer size='xl' />
               
        </Content>
    </Page>
    )
};

export default Mint;

const SmartContract = styled.div `
    h3 {
        margin-bottom: 20px;
    }
    text-align: center;
    a {
        color: #fff;
        opacity: .9;
        &:hover {
            opacity: 1;
        }
    }
`

const Content = styled.div `
    margin-top: 25px;

    h2 {
        font-size: 32px;
        text-align: center;
    }
    h3 {
        margin-top: 10px;
        text-align: center;
    }
    width: 100%;

    .connectCard {
        .btn, button {
            width: 100%;
        }
    }

    .mobileOnly {
        display: none;
    }

    ${MobileStyles(`
        .mobileOnly {
            position: relative;
            top: 30px;
            text-align: center;
            display: block;
        }
    `)};
`
const Center = styled.div `
    padding: 0px 20px;
    max-width: 600px;
    margin: 0px auto;

    div.card {
        padding: 20px;
        max-width: 400px;
        margin: 0px auto;
    }
`
const Timeline = styled.div `
    padding: 0px 20px;
    max-width: 700px;
    margin: 0px auto;
`

const StyledTopBar = styled.div`
    //background: rgba(24, 51, 85, .8);
    //background: linear-gradient(180deg, rgba(65,52,129,0.5) 0%, rgba(97,83,167,0.5) 100%); 
    //border-bottom: 2px solid rgba(255,255,255,0.1);
    position: relative;
    width: 100%;
    z-index: 2;
    height: ${props => props.theme.topBar.height}px;

    &.fixed {
        top: 0px;
    }
`


const Nav = styled.div `
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: 16px;

  .logo {
    cursor: pointer;
    label {
      cursor: pointer;
    }
  }

    a {
        padding: 10px 10px;
    }

    ${MobileStyles(`
        display: block;
        text-align: center;
        padding: 10px 0px;
        font-size: 12px;
        .logo {
            display: inline-block;
        }
    `)};
`

const NavLeft = styled.div `
    img {
        height: 40px;
    }  
`

const NavRight = styled.div `
    display: flex;
    align-items: center;

    ${MobileStyles(`
        justify-content: center;
        margin-top: 20px;
    `)};
    
`

const Whitelisted = styled.div `
    padding-top: 50px;
    text-align: center;
    width: 300px;
    margin: 0px auto;
    font-size: 18px;

    svg {
        font-size: 36px;
        margin-bottom: 15px;
    }

    .msg2 {
        span {
            position: relative;
            top: 5px;
            width: 100px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

`

const Whitelist = styled.div `
    cursor: default;
    background-color: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    display: inline-block;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    margin-left: 1px;
`

const Tokens = styled.div `
    cursor: default;
    background-color: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    display: inline-block;
    padding: 10px 18px 10px 15px;
    display: flex;
    align-items: center;
    margin-left: 1px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    img {
        width: 18px;
        margin-right: 5px;
    }
    a {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0px;
    }
    &.link {
        cursor: pointer;
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
`

const Disconnect = styled.div `
    margin-right: 5px;
    opacity: .8;
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
    ${MobileStyles(`
        display: none;
    `)};
`

const Account = styled.div `
    display: inline-block;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    > div {
        display: inline-block;
    }

    .hov {
        opacity: 0;
    }

    svg {
        font-size: 10px;
        margin-right: 10px;
        color: ${props => props.theme.buttons.primary.background};
    }
    span {
        width: 100px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    background-color: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);

    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;


    // &:hover {
    //     background-color: rgba(255, 255, 255, 0.2);
    //     .hov {
    //         opacity: 1;
    //     }
    //     .acc {
    //         opacity: 0;
    //     }
    // }
`