import React, { createContext, useContext, useEffect, useState } from 'react';

export type AccountContextType = {
    //accountUser: UserTypes | null;
    accountUser: any;
    accountData: any;
}

const AccountContext = createContext<AccountContextType>({ 
    accountUser: null,
    accountData: null
})

const AccountProvider: React.FC = ({ children }) => {

    const [accountUser, setAccountUser] = useState<any>(null)
    const [accountData, setAccountData] = useState<any>(null) 

    /*
    useEffect(() => {
        if ( account ) {
            console.log(account)
        }
    }, [account])

    useEffect(() => {
        console.log(wallet)
        console.log(wallet.status)
        if ( wallet.status === 'connected' && wallet.account ) {
            
        }

        if ( wallet.status === 'disconnected' ) {
            setAccountUser(null)
            setAccountData(null)
        }
    }, [wallet.status])
    */

    return  (
        <AccountContext.Provider value={{ accountUser, accountData }}>
            {children}
        </AccountContext.Provider>
    )
}
export  { AccountContext,  AccountProvider }
