import React from 'react'
import styled from 'styled-components'
import { BorderRadius, DropShadow, GlassBackground } from '../../contexts/Theme/Mixins'


interface CardData {  
  variant?: 'default' | 'glass',
  className?: string
}

const Card: React.FC<CardData> = ({ children, className, variant="glass" }) => (
  <StyledCard className={`card ${variant} ${className}`}>
    {children}
  </StyledCard>
)

const StyledCard = styled.div`  
    max-width:100%;
    background-color: ${props => props.theme.card.background};
    padding: ${props => props.theme.spacing[4]}px;
    ${BorderRadius()};
    ${DropShadow()};
  
    &.glass {
        ${GlassBackground()};
    }
`

export default Card