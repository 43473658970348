import Web3 from 'web3'; 
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'ethereumjs-util';

function encodeAddr(addr) {
    return Web3.utils.soliditySha3({t: 'address', v:addr});
}

export function createMerkleTree(whitelist) {
    const leaves = whitelist.map(x => encodeAddr(x));
    const tree = new MerkleTree(leaves, keccak256.keccak256, {sortPairs: true});
    return tree;
}

export function createMerkleProof(tree, address) {
    const leaf = encodeAddr(address);
    return tree.getHexProof(leaf);
}

export function verifyMerkleProof(proof, address, tree) {
    const root = tree.getHexRoot();
    const leaf = encodeAddr(address);
    return tree.verify(proof, leaf, root);
}
