import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext, ThemeContextProps, themes } from '../../../contexts/Theme/Theme';
import { NavHashLink } from 'react-router-hash-link';


import water from '../../../assets/water.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Link from '../../../components/Link';
import { MobileStyles } from '../../../contexts/Theme/Mixins'
import { faExternalLink, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

const Top: React.FC = ({  }) => {

  return (  
    <Content>
        <Left>
            {/* <Project>Fuzzy Felons</Project> */}
        </Left>
        <Center>
            <NavHashLink smooth to="#roadmap">
                Roadmap
            </NavHashLink>
            <NavHashLink smooth to="#team">
                Team
            </NavHashLink>
            <NavHashLink smooth to="#faq">
                FAQ
            </NavHashLink>
            
            <Link href='https://etherscan.io/address/0x5759110402AA65241FD03435646aFD01a91Ab948#code'>
                Smart Contract <FontAwesomeIcon icon={faExternalLink} size='1x' />
            </Link>
            {/* <Link to='#'>
                Roadmap
            </Link>
            <Link to='#'>
                Team
            </Link>
            <Link to='#'>
                FAQ
            </Link> */}
        </Center>
        <Right>
            <Link href='https://fuzzyfelons.shop'>
                <FontAwesomeIcon icon={faShoppingCart} size='1x' />
            </Link>
            <Link href='http://discord.gg/fuzzyfelons'>
                <FontAwesomeIcon icon={faDiscord} size='1x' />
            </Link>
            <Link href='https://twitter.com/fuzzyfelons'>
                <FontAwesomeIcon icon={faTwitter} size='1x' />
            </Link>
        </Right>
    </Content>
  );
};

export default Top;

const Content = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const Left = styled.div `
    display: flex;
    justify-content: left;
    padding: 15px;
    width: 200px;

    ${MobileStyles(`
        width: 0%;
        display: none;
    `)};
`

const Project = styled.div `
    font-family: 'Titan One', sans-serif;
    font-size: 26px;
`

const Center = styled.div `
    justify-content: center;
    padding: 15px 0px;
    a {
        margin: 0px 10px;
        color: ${props => props.theme.text.primary.color};
    }

    svg {
        font-size: 12px;
        position: relative;
        top: -2px;
    }

    ${MobileStyles(`
        width: 100%;
        text-align: center;
        font-size: 14px;
        margin-top: 10px;
    `)};
`

const Right = styled.div `
    display: flex;
    width: 200px;
    justify-content: right;
    padding: 15px;
    a {
        margin-left: 15px;
    }

    ${MobileStyles(`
        width: 0%;
        display: none;
    `)};
`