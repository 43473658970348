import React, { Component, useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { BorderRadius, MobileStyles } from '../../../contexts/Theme/Mixins'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarning, faExternalLink } from '@fortawesome/free-solid-svg-icons';


interface MintError {
    message: string
}

const MintError: React.FC<MintError> = ({ message  }) => {
    
    return (  
    <Content className='mintError'>
        <div className='one'>
            <FontAwesomeIcon icon={faWarning} />
        </div>
        <div className='two'>
            { message }
        </div>
    </Content>
    );
};

export default MintError;

const Content = styled.div `
    padding: 20px;
    background: rgba(0,0,0,.5);
    -moz-box-shadow:    inset 0 0 10px rgba(0,0,0,.2);
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,.2);
    box-shadow:         inset 0 0 10px rgba(0,0,0,.2);
    color: #24e7de;
    ${BorderRadius()}
    display: flex;
    .one {
        width: 25px;
    }
    .two {
        flex: 1;
    }
`
