import React from 'react';
import styled from 'styled-components';

import Link from '../Link'
import ImageLogo from '../../assets/logo_v02.png';


interface LogoProps {
    size?: number
}

const Logo: React.FC<LogoProps> = ({
    size = 32
}) => {
  return (
    <StyledLogo className='logo'>   
      <Link to='/'>   
        <img src={ImageLogo} /> 
      </Link>
    </StyledLogo>
  );
};


interface LogoStyleProps {
    height: number
}

const StyledLogo = styled.div `
    display: flex;
    align-items: center;

    img {
        max-width: 100%;
    }

`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.text.primary.color};
`;

const Community = styled.span `
  font-size: 21px;
`

export default Logo;
