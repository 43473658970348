import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Logo from '../../components/Logo'
import Card from '../../components/Card';
import Page from '../../components/Page';
import StyledLink from '../../components/Link';
import Spacer from "../../components/Spacer";
import Button from '../../components/Button';
import { ThemeContext, ThemeContextProps, themes } from '../../contexts/Theme/Theme';
import { AccountContext } from '../../contexts/Account/AccountContext';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';


import Top from './components/Top';
import Faq from './components/Faq';
import About from './components/About';
import Team from './components/Team';
import Roadmap from './components/Roadmap';
import Soon from './components/Soon';
import Footer from '../../components/Footer';
import { MobileStyles } from '../../contexts/Theme/Mixins';


const Home: React.FC = ({  }) => {
  const { theme } = useContext(ThemeContext) as ThemeContextProps;
  const { accountUser } = useContext(AccountContext);

  return (
    <Page footer={false}>  
        {/* <Construction>
            <h1 className='fuzzyfont'>Under Maintenance</h1>
        </Construction> */}
    <ParallaxProvider>      
        <Top/>
        <Soon/>
        <Spacer size='lg' /> 
        <Spacer size='lg' /> 
        <About/>
        <Roadmap/>
        <Team/>
        <Faq/>
        <Spacer size='lg' /> 
        <Footer />
    </ParallaxProvider>
    </Page>
  )
};

export default Home;

const Construction = styled.div `
    margin-top: 250px;

    h1 {
        text-align: center;
        font-size: 55px;
    }

    ${MobileStyles(`
        margin-top: 100px;
        h1 {
            font-size: 34px;
        }
    `)};
`