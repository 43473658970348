import React, { Component, useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import Spacer from '../../../components/Spacer';
import Button from '../../../components/Button';
import NumberPicker from '../../../components/NumberPicker';
import  Web3 from 'web3';
import { getWhiteList, getTxEtherscanUrl, getOpenseaUrl } from '../functions';
import { createMerkleTree, createMerkleProof, verifyMerkleProof } 
    from '../merkleTree.js'
import Spinner from '../../../components/Spinner';
import MintError from './MintError';

var _ = require('lodash')
const Fade = require('react-reveal/Fade')

interface FormDataProps {
    count: number
}

interface onSuccessProps {
    receipt: any,
    url: SVGStringList
}

interface Props {
    Contract: any,
    phase: number,
    account: string,
    balance: string,
    tokens: number,
    onSubmit: () => Promise<boolean>,
    onSuccess: (data:any) => void
}

const MintForm: React.FC<Props> = ({ 
    Contract,
    phase, 
    account, 
    balance, 
    tokens,
    onSubmit,
    onSuccess 
}) => {
    const formRef = useRef(null)
    const [formError, setError] = useState<string | null>(null)
    const [minTokens, setMinTokens] = useState<number>(1)
    const [maxTokens, setMaxTokens] = useState<number | null>(null)
    const [isEligible, setIsEligible] = useState<boolean>(true)
    const [eligibleMsg, setEligibleMsg] = useState<string | null>(null)
    const [submitStatus, setSubmitStatus] = useState<string>('ready')
    const [etherscanUrl, setEtherscanUrl] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(true)

    //const [openseaUrl, setOpenSeaUrl] = useState<string>('')    

    const whitelist = getWhiteList()
    const tree = createMerkleTree(whitelist)
    
    useEffect(() => {
        initialize()
    }, [phase, account, tokens])

    const Reset = () => {
        setSubmitStatus('ready')
        setEtherscanUrl('')
        setEligibleMsg('')
        setError('')
    }

    // Token eligibility state
    const initialize = async () => {
        setLoading(true)
        Reset()
        const mintableTokens = await Contract.getMaxMintableTokens(account)
        setMaxTokens(mintableTokens)
        setLoading(false)
        
        if ( mintableTokens == 0 && phase == 1 ) {
            setIsEligible(false)
            setEligibleMsg("You can't mint any more tokens during PHASE 1. Whitelisters can mint up to four Felons during PHASE 2.")
            return
        }
        if ( mintableTokens == 0 && phase >= 2 ) {
            setIsEligible(false)
            setEligibleMsg("You've minted the max number of Felons.")
            return
        }
        if ( (phase == 1 || phase == 2) ) {
            if ( whitelist.indexOf(account.toLowerCase()) == -1 ) {
                setIsEligible(false)
                setEligibleMsg(`This wallet address ${truncatedAccount()} is not on the whitelist. If you return during PHASE 3 (public phase) you can mint up to two Felons.`)
                return
            }
        }
        if ( mintableTokens == 1 ) {
            setIsEligible(true)
            setEligibleMsg(`You can mint ${mintableTokens} Felon`) 
            return
        }

        if ( mintableTokens ) {
            setIsEligible(true)
            setEligibleMsg(`You can mint up to ${mintableTokens} Felons`)
        }
        
    }

    const truncatedAccount = () =>{
        return  _.truncate(account, {
            'length': 12
        });
    }

    const ClearErrors = () => {
        setError(null)
    }

    const Submit = async () => {
        setSubmitStatus('submitting')

        const before = await onSubmit()
        if ( before == false ) {
            setSubmitStatus('ready')
            return
        }

        var data = getFormData()
        const numTokens = data.count
        var proof:any = []

        if ( phase == 0 ) {
            setError("Minting is not yet active")
            setSubmitStatus('ready')
            return
        } else if ( phase == 1 || phase == 2 ) {

            // Whitelist
            if ( whitelist.indexOf(account.toLowerCase()) == -1 ) {
                setError(`This wallet address ${truncatedAccount()} is not on the whitelist.`)
                setSubmitStatus('ready')
                return
            }

            proof = createMerkleProof(tree, account)
            if ( !verifyMerkleProof(proof, account, tree)) {
                setError("Merkle Proof did not verify your address. This likely means you are not on the whitelist.")
                setSubmitStatus('ready')
                return
            }
        } else if ( phase == 4 ) {
            setError("Minting is not active");
            setSubmitStatus('ready')
            return
        }

        // Funds
        const mintPrice = await Contract.getMintPrice(data.count)
        if ( Web3.utils.toBN(balance) as any < mintPrice ) {
            setError(`Insufficient funds in wallet (${truncatedAccount()})`)
            setSubmitStatus('ready')
            return
        }

        // Send
        var txHash:string
        Contract.contract.methods.mint(numTokens, proof)
            .send({from: account, value: mintPrice})
            .on('transactionHash', function(hash:string) {
                txHash = hash
                setEtherscanUrl(getTxEtherscanUrl(hash))
                setSubmitStatus('pending')
            })
            .on('receipt', function(receipt:any) {
                const url = getOpenseaUrl(account)
                setSubmitStatus('success')
                onSuccess({
                    receipt: receipt, 
                    url: url
                })
            })
            .on('error', function(err:any) {
                setSubmitStatus('ready')
                showErrorCode(txHash)
            }
        )
    }

    const showErrorCode = async (txHash:string) => {
        const code = await Contract.getRevertReason(txHash)
        if ( code ) {
            setError(code)
        }
    }

    const getFormData = ():FormDataProps => {
        var formData = new FormData(formRef.current as any)
        if ( maxTokens == 1 ) {
            return {
                count: 1
            }
        } 
        return {
            count: formData.get('count') as unknown as number
        }
    }

    return (  
        <Content>
            { loading ? (
                <Loading>
                    <Spinner />
                </Loading>
            ) : (
                <>
                    { isEligible ? (
                        <>
                        { (maxTokens === 0 ) &&
                            <Eligible>
                                <h3>{ eligibleMsg }</h3>
                            </Eligible> 
                        }
                
                        { (maxTokens != null && maxTokens > 0 ) &&
                            <>
                            <form ref={formRef}>
                                { eligibleMsg != null &&
                                    <Eligible>
                                        <h3>{ eligibleMsg }</h3>
                                    </Eligible>
                                }
                                <Spacer size='md' />
                                { ( maxTokens > 1) &&
                                    <>
                                    <NumberPicker 
                                        min={1} 
                                        value={minTokens}
                                        max={maxTokens} />
                                    <Spacer size='md' />
                                    </>
                                }         
                            </form> 
                                
                            { submitStatus == 'ready' &&
                                <Button background='primary' 
                                        text='Mint Now' onClick={Submit}/>
                            }
                
                            { (submitStatus == 'submitting' || submitStatus == 'pending') &&
                                <Button background='primary' onClick={Reset}>
                                    <Spinner/>
                                </Button>
                            }
                
                            { etherscanUrl && 
                                <PendingTx>
                                    <a href={etherscanUrl} target='_blank'>
                                        Transaction on Etherscan &nbsp; <FontAwesomeIcon 
                                            icon={faExternalLink} />
                                    </a>
                                </PendingTx>  
                            }
                
                            { formError &&
                                <>
                                <Spacer size='sm' />
                                <FormError onClick={ClearErrors}>
                                    <MintError message={formError} />
                                </FormError>
                                </>
                            }
                            </>
                        }
                        </>
                    ):(
                        <>
                            { eligibleMsg &&
                                <MintError message={eligibleMsg} />
                            }
                        </>
                    )}  
                </>
            )}                  
        </Content>
    )
}

export default MintForm

const Loading = styled.div `
    text-align: center;
`

const Eligible = styled.div `
    text-align: center;
`

const Content = styled.div `
    width: 100%;
    .btn, button {
        width: 100%;
    }
    h3 {
        text-align: center;
    }
    .mintError {
        cursor: pointer;
    }
`

const FormError = styled.div `

`

const PendingTx = styled.div `
    margin-top: 20px;
    text-align: center;
    a {
        padding: 20px;
        color: #fff;
        opacity: .8;
        &:hover {
            opacity: 1;
        }
    }

`