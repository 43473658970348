import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { MobileStyles } from '../../../contexts/Theme/Mixins'


interface HeaderProps {
    title: string,
    subtitle?: string,
    background?: string
}

const SectionHeader: React.FC<HeaderProps> = ({ 
    title,
    subtitle,
    background='inherit' }) => {

  return (  
    <Content background={background}>
        <Line/>
        <h2 className='fuzzyfont'>{title}</h2>
        { subtitle &&
            <h3>
                { subtitle }
            </h3>
        }
        <Line/>
    </Content>
  );
};

export default SectionHeader;

interface SectionStyleProps {
    background: string
}
  

const Content = styled.div<SectionStyleProps> `
    position: relative;
    overflow: hidden; 

    background: ${props => props.background};
    text-align: center;

    padding: 45px 15px;

    h2 {   
        font-size: 62px;
    }

    h3 {
        margin-top: 5px;
        font-size: 25px;
    }

    ${MobileStyles(`
        h2 {
            font-size: 42px;
        }
        h3 {
            font-size: 20px;
        }
    `)};
`



const Line = styled.div `
    height: 2px;
    //background: #fff;
`