import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ThemeStore, ThemeProvider } from './contexts/Theme';
import ModalsProvider from './contexts/Modals';
import { AccountContext, AccountProvider } from './contexts/Account/AccountContext';
import config from './config';


import firebaseApp, { analytics, auth, functions } from './firebase';
import { getAnalytics, logEvent } from "firebase/analytics";
import './App.css';

// Views
import Home from './views/Home/Home';
import Mint from './views/Mint/Mint';


// Firebase Emulator Requirements
import { getApp } from "firebase/app";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";

if ( window.location.hostname === 'localhost' ) {
  connectAuthEmulator(getAuth(), "http://localhost:9099");
  //connectDatabaseEmulator(getDatabase(), "localhost", 9000);
  connectFunctionsEmulator(getFunctions(), "localhost", 5001);
}

const App: React.FC = () => {

  return (
    <Providers>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mint" element={<Mint />} />
        </Routes>
    </Providers>
  );
};

const Providers: React.FC = ({ children }) => {
  const firebase = firebaseApp
  const location = useLocation()
  useEffect(() => {
      logEvent(analytics, 'page_view', {
        'page_location': location.pathname + location.search + location.hash,
      })
  },[location]) 

  return (
    <ThemeStore>     
        <ThemeProvider>
          <ModalsProvider>
            <AccountProvider>
              <>
                {children}
              </>
            </AccountProvider>
          </ModalsProvider>
        </ThemeProvider>
    </ThemeStore>
  );
};

export default App;