import React from 'react';
import styled from 'styled-components';
import { BorderRadius } from '../../contexts/Theme/Mixins'

import Card from '../Card';
import CardContent from '../CardContent';
import Container from '../Container';

export interface ModalProps {
  style?: 'default' | 'glass';
  onDismiss?: () => void;
}

const Modal: React.FC<ModalProps> = ({ style = "glass", children }) => {
  return (
    <Container size="sm">
      <StyledModal className={style}>
        <Card variant={style}>
          <CardContent>{children}</CardContent>
        </Card>
      </StyledModal>
    </Container>
  );
};

const StyledModal = styled.div`
    ${BorderRadius()};
    position: relative;
    color: ${(props) => props.theme.text.primary.color};
    //backdrop-filter: blur(5px); 
    background: rgba(48,62,131,1);
    background: radial-gradient(circle, rgba(74,100,162,1) 0%, rgba(48,62,131,1) 100%);
    //background: radial-gradient(circle, rgba(83,107,167,1) 0%, rgba(52,65,129,1) 100%);
`;

export default Modal;
