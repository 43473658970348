import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Spacer from '../../../components/Spacer';
import { Parallax } from 'react-scroll-parallax';
import { MobileStyles } from '../../../contexts/Theme/Mixins'

// Images
import Mosaic from '../../../assets/mosaic.jpg';
import Mosaic2 from '../../../assets/mosaic2.png';
import StandingFelon from '../../../assets/standingFelon_v01.png';
import Button from '../../../components/Button';

const About: React.FC = ({  }) => {

  return (  
    <Content>
        {/* <Image>
            <Parallax speed={10}>
                <img src={RoofA} />
            </Parallax>
        </Image> */}

        <Feature>
            <a href='#episode1'>
                <h2 className='fuzzyfont'>Episode One</h2>
            </a>

            <Video>
                <div className='videoSize'>
                    <div className='embed-container'>
                        <iframe src="https://www.youtube.com/embed/jCJ5VzCLrO0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                    </div>
                </div>
            </Video>
        </Feature>

        <VideoList>

            <div className='video'>
                <div className='videoleft'>
                    <div className='embed-container'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/4ItZD6e5vZg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div>
                <div className='videoright'>
                    <a href='#trailer'>
                        <h3 className='fuzzyfont'><a href='https://www.youtube.com/watch?v=4ItZD6e5vZg' target='_blank' rel='nofollow'>Episode Two</a></h3>
                        <h5>Runtime 3:57</h5>
                    </a>
                </div>
            </div>
 
            <div className='video'>
                <div className='videoleft'>
                    <div className='embed-container'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/8B7Y-lTFx5c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div>
                <div className='videoright'>
                    <a href='#trailer'>
                        <h3 className='fuzzyfont'><a href='https://www.youtube.com/watch?v=8B7Y-lTFx5c' target='_blank' rel='nofollow'>Episode Three</a></h3>
                        <h5>Runtime 4:16</h5>
                    </a>
                </div>
            </div>

            <div className='video'>
                <div className='videoleft'>
                    <div className='embed-container'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/5tmezfPPDvM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div>
                <div className='videoright'>
                    <a href='#trailer'>
                        <h3 className='fuzzyfont'><a href='https://www.youtube.com/watch?v=5tmezfPPDvM' target='_blank' rel='nofollow'>Episode Four</a></h3>
                        <h5>Runtime 2:26</h5>
                    </a>
                </div>
            </div>

            <div className='video'>
                <div className='videoleft'>
                    <div className='embed-container'>
                        <iframe src="https://player.vimeo.com/video/716915637?h=d4396e8607" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                    </div>
                </div>
                <div className='videoright'>
                    <a href='#trailer'>
                        <h3 className='fuzzyfont'>Fuzzy Felons Trailer</h3>
                        <h5>Runtime 2:00</h5>
                    </a>
                </div>
            </div>

        </VideoList>

        <Sides>
            <Left>
                {/* <img src={StandingFelon} /> */}
                {/* <Parallax speed={10}>
                    <img src={RoofA} />
                </Parallax> */}

                <BoxShadow />
                <LeftInner>

                    <Parallax speed={-10}>
                        <ImageWrap>
                            <img src={Mosaic2} />
                        </ImageWrap>
                        <ImageWrap>
                            <img src={Mosaic2} />
                        </ImageWrap>
                    </Parallax>
                </LeftInner>

            </Left>
            <Right>
                <Inner>

                    <h3>Introducing Fuzzy Felons</h3>
                    <Spacer size='md' />
                    <Paragraph>
                        Fuzzy Felons is an NFT collection of 3,333 unique characters, rooted in childhood nostalgia but with an unmistakable adult edge. Created by award-winning animation director, Pete Sefchik (Dreamworks, Weta, Skywalker Ranch), the collection is the foundation of an episodic sketch comedy series. Holders can have their Felons cast in the show, earn royalties, and partake in weekly Vault Heights for prizes and earnings.
                    </Paragraph>
                   
                    {/* <Spacer size='md' />
                    <Button>
                        Join Us!
                    </Button> */}
                </Inner>
            </Right>
        </Sides>
    </Content>
  );
};

export default About;

const VideoList = styled.div `
    max-width: 700px;
    margin: 0px auto;

    .video {
        display: flex;
        padding: 20px 0px;
        .videoleft {
            width: 200px;
            margin-right: 25px;
        }
        a {
            color: inherit;
        }
    }

    h3 {
        font-size: 32px;
        color: #fff;
    }
    h5 {
        margin-top: 10px;
        color: #fff;
        opacity: .75;
    }

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; overflow: hidden; 
    max-width: 100%; 
  } 
  
  .embed-container iframe, 
  .embed-container object, 
  .embed-container embed { 
    position: absolute; 
    top: 0; left: 0; 
    width: 100%; 
    height: 100%; 
  }  
`

const Feature = styled.div `
    padding-top: 50px;

    background-color: rgba(255, 255, 255, 0.1);

    a {
        color: inherit;
    }
    h2 {
        text-align: center;
    }

    .videoSize {
        max-width: 700px;
        margin: 0px auto;
    }
  .embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; overflow: hidden; 
    max-width: 100%; 
  } 
  
  .embed-container iframe, 
  .embed-container object, 
  .embed-container embed { 
    position: absolute; 
    top: 0; left: 0; 
    width: 100%; 
    height: 100%; 
  }   
`

const Video = styled.div `
    padding: 50px;
    > div {
        max-width: 300px;
        margin: 0px auto;
    }
    ${MobileStyles(`
        padding: 20px 0px 0px 0px;
    `)};
`

const Content = styled.div `
   overflow: hidden;
   position: relative;
   font-size: 20px;
`
const Sides = styled.div `
    display: flex;
    z-index: 5;
`

const BoxShadow = styled.div `
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
`

const Left = styled.div `
    width: 50%;
    position: relative;

    ${MobileStyles(`
        width: 0%;
        display: none;
    `)};
`

const LeftInner = styled.div `
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
`

const ImageWrap = styled.div `
    position: relative;
    top: -120px;
    left: -120px;

    img {
        width: 135%;
    }
`

const Right = styled.div `
    flex: 1;
    background: rgb(97,83,167);
    background: radial-gradient(circle, rgba(97,83,167,1) 0%, rgba(65,52,129,1) 100%);
    ${MobileStyles(`
        width: 100%;
    `)};
`

const Inner = styled.div `
    padding: 75px;
    ${MobileStyles(`
        h3 {
            text-align: center;
        }
        padding: 35px;
    `)};
`

const Paragraph = styled.div `
    line-height: 145%;
`

