import React from 'react'
import styled from 'styled-components'
//const tintShadeColor = require('tint-shade-color');

/* 
Hue: Pure Colors
Tints: Hue + White
Tones: Hue + Grey
Shades: Hue + Black
*/

const themeProps = {
    dark: {
        global: {
            fontFamily: "'Inter', sans-serif",
            padding: 30,
            borderRadius: 20,
            borderColor: '#d6d6d6',
            shadowColor: '#131315',
            positiveColor: '#34d04e', // red
            negativeColor: '#d0344a' // green
        },
        page: {
            background: '#152836',
            maxWidth: 1200
        },
        modal: {
            background: '#1d3241',
            maxWidth: 600,
            input: {
                background: '#152836'
            }
        },
        input: {
            background: '#fff',
            borderColor: '#ccc',
            select: {
                background: '#1d3241',
                borderColor: '#ccc',
                option: {
                    backgroundHover: '#24a9a3',
                    backgroundFocus: '#0191c7'
                }
            }
        },
        topBar: {
            background: '#fff',
            height: 60
        },
        botBar: {
            background: '#ddd', //'#1a1b1f',
            height: 60
        },
        nav: {
            background: '#0e1c25',
            backgroundHover: '#ccc',
            color: '#333',
            icon: {
                background: '#0e1c25',
            },
            active: {
                highlight: '#cf4691',
                background: '#2C2E39'
            }
        },
        card: {
            background: '#0e1c25',
            borderColor: '#d6d6d6',
        },
        table: {
            borderColor: '#d6d6d6',
            evenRowBackground: '#202128',
        },
        buttons: {
            primary: {
                background: '#24a9a3',
                backgroundHover: '#29BAB3',
                color: '#fff',
                colorHover: '#ffffff'
            },
            secondary: {
                background: '#6261c6',
                backgroundHover: '#6968d5',
                color: '#fff',
                colorHover: '#F7FCFE' 
            },
            tertiary: {
                background: 'rgba(98, 97, 198, .4)',
                backgroundHover: 'rgba(98, 97, 198, .4)',
                color: '#fff'
            }
        },
        text: {
            primary: {
                color: '#fff',
                hoverColor: '#98a5ad',
                highlightColor: '#98a5ad',
            },
            secondary: {
                color: '#959799',
                hoverColor: '#a3a5a7',
                highlightColor: '#a3a5a7',
            },
            highlight: {
                1: {
                    color: '#cf4691',
                    hoverColor: '#e64da1',
                }
            },
            header: {
                color: '#f1d5e4'
            }
        },
        spacing: {
            1: 4,
            2: 8,
            3: 16,
            4: 24,
            5: 32,
            6: 48,
            7: 64,
            8: 86,
        }
    },
    light: {
        global: {
            fontFamily: "'Inter', sans-serif",
            padding: 30,
            borderRadius: 20,
            borderColor: '#d6d6d6',
            shadowColor: '#131315',
            positiveColor: '#34d04e', // red
            negativeColor: '#d0344a' // green
        },
        page: {
            background: '#eee',
            maxWidth: 1200
        },
        modal: {
            background: '#fff',
            maxWidth: 600,
            input: {
                background: '#eee'
            }
        },
        input: {
            background: '#fff',
            borderColor: '#ccc',
            select: {
                background: '#ddd',
                borderColor: '#ccc',
                option: {
                    backgroundHover: '#24a9a3',
                    backgroundFocus: '#0191c7'
                }
            }
        },
        topBar: {
            background: '#fff',
            height: 60
        },
        botBar: {
            background: '#ddd', //'#1a1b1f',
            height: 60
        },
        nav: {
            background: '#fff',
            backgroundHover: '#ccc',
            color: '#333',
            icon: {
                background: '#fff',
            },
            active: {
                highlight: '#cf4691',
                background: '#2C2E39'
            }
        },
        card: {
            background: '#fff',
            borderColor: '#d6d6d6',
        },
        table: {
            borderColor: '#d6d6d6',
            evenRowBackground: '#202128',
        },
        buttons: {
            primary: {
                background: '#24a9a3',
                backgroundHover: '#24a9a3',
                color: '#fff',
                colorHover: '#ffffff'
            },
            secondary: {
                background: '#0191c7',
                backgroundHover: '#0191c7',
                color: '#fff',
                colorHover: '#F7FCFE' 
            },
            tertiary: {
                background: '#eee',
                backgroundHover: '#eee',
                color: '#333'
            }
        },
        text: {
            primary: {
                color: '#333',
                hoverColor: '#222',
                highlightColor: '#222',
            },
            secondary: {
                color: '#ADADB2',
                hoverColor: '#C2C2C5',
                highlightColor: '#C2C2C5',
            },
            highlight: {
                1: {
                    color: '#cf4691',
                    hoverColor: '#e64da1',
                }
            },
            header: {
                color: '#f1d5e4'
            }
        },
        spacing: {
            1: 4,
            2: 8,
            3: 16,
            4: 24,
            5: 32,
            6: 48,
            7: 64,
            8: 86
        }
    },
}


export default themeProps