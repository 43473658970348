import { initializeApp } from "firebase/app"; // no compat for new SDK
import { getDatabase, ref } from 'firebase/database'
import { getAuth, connectAuthEmulator, onAuthStateChanged } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from "@firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


type firebaseConfigTypes = {
    apiKey: string,
    authDomain: string,
    databaseURL: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string
}


const firebaseConfigs: { [env: string]: firebaseConfigTypes } = {
    // firebase app: Website Dev
    development: {
        apiKey: "AIzaSyBPZHsT4Ew22NGbSd4jsd7yXty9lDMcAuE",
        authDomain: "fuzzy-felons.firebaseapp.com",
        databaseURL: "",
        projectId: "fuzzy-felons",
        storageBucket: "fuzzy-felons.appspot.com",
        messagingSenderId: "849382533704",
        appId: "1:849382533704:web:5f1b3e20b360c1193d067d",
        measurementId: "G-N8QDZ7VZF0"
    },
    // firebase app: Website
    production: {
        apiKey: "AIzaSyBPZHsT4Ew22NGbSd4jsd7yXty9lDMcAuE",
        authDomain: "fuzzy-felons.firebaseapp.com",
        databaseURL: "",
        projectId: "fuzzy-felons",
        storageBucket: "fuzzy-felons.appspot.com",
        messagingSenderId: "849382533704",
        appId: "1:849382533704:web:5f1b3e20b360c1193d067d",
        measurementId: "G-N8QDZ7VZF0"
    },
}

export const firebaseConfig = firebaseConfigs[process.env.NODE_ENV || "development"]

const firebaseApp = initializeApp(firebaseConfig);

//export const database = getDatabase(firebaseApp)

export const auth = getAuth(firebaseApp);

export const functions = getFunctions(firebaseApp);

export const analytics = getAnalytics(firebaseApp); 

if ( process.env.NODE_ENV == 'development' ) {
    console.log('development')
    connectAuthEmulator(auth, "http://localhost:9099");
}

//export const rootRef = ref(database, 'root')

export default firebaseApp;
