import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Link from '../../../components/Link';
import Spacer from '../../../components/Spacer';
import { MobileStyles } from '../../../contexts/Theme/Mixins'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Parallax } from 'react-scroll-parallax';

// Images
import RoadmapI10 from '../../../assets/firstreveal.jpg';
import RoadmapI20 from '../../../assets/fuzzyfelonsJude_v03.gif';
import RoadmapI30 from '../../../assets/new05.png'; 
import RoadmapI40 from '../../../assets/pubStill03_v02.jpg';
import RoadmapI50 from '../../../assets/new04.png';
import RoadmapI60 from '../../../assets/new02.png';
import RoadmapI70 from '../../../assets/new03.png';
import RoadmapI80 from '../../../assets/fuzzyfelons004.gif';
import RoadmapI90 from '../../../assets/pubStill01_v03.jpg';
import RoadmapI100 from '../../../assets/pubStill02_v03.jpg';



import Button from '../../../components/Button';
import SectionHeader from './SectionHeader';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Fade = require('react-reveal/Fade');

const RowHeight = 350;
const ImageHeight = 800;
const BackgroundColor = '#182f3e';

const Roadmap: React.FC = ({  }) => {

  return (  
    <Content id='roadmap'>

        <Spacer size='lg' />

        <SectionHeader title='Roadmap' subtitle="How's this thing going to roll out?" />

        <Spacer size='lg' />

        <Row image={RoadmapI10} side='left'>
            <Progress 
                amount={10} 
                complete={true}
                color='#71c737'
                date='First week of February'
                status='Completed' />
            
            <Title>Twitter & Discord go LIVE</Title>
        </Row>

        <Row image={RoadmapI20} position='top' side='right'>
            <Progress 
                amount={20} 
                complete={true}
                color={'#f16f30'}
                date={'First week of March'}
                status='Completed' />
            
            <Title>Charity Release</Title>
            <Subtitle>
                Through the sale of 333 Charity Felons, Fuzzy Felons was able to raise over $27,000 for St. Jude Children's Hospital. All royalties from secondary sales go to the hospital as well, providing a steady stream of charitable donations from the Fuzzy Felon community. 
            </Subtitle>
            {/* <Title>Website goes LIVE</Title> */}
        </Row>

        <Row image={RoadmapI30} position='top' side='left'>
            <Progress 
                amount={30} 
                complete={true}
                color={'#0281d9'}
                date={'March 31st'}
                status='Completed' />
            <Title>Prison Break</Title>
            <Subtitle>
                3,333 will escape from prison and be dispersed among the unsuspecting NFT community. For details on the Mint, see the FAQ section below.
            </Subtitle>
        </Row>

        <Row image={RoadmapI40} position='top' side='right'>
            <Progress 
                amount={40} 
                complete={true}
                color={'#9d4eb8'}
                date={'Late April 2022'}
                status='Completed' />
            <Title>
                Merchandise Drop
            </Title>
            <Subtitle>
                Hoodies, Trucker Caps & More. Deep discounts for Holders.
            </Subtitle>
        </Row>

        <Row image={RoadmapI50} side='left'>
            <Progress 
                amount={50} 
                complete={true}
                color={'#ce51c4'}
                date={'May 2022'}
                status='Completed' />
            <Title>
                Release Fuzzy Felons Trailer
            </Title>
            <Subtitle>
                Release a 30 second trailer that demonstrates the unique suitless motion capture technology that Move.AI has developed. It will also offer a glimpse into the look of the show, and it's format. 
            </Subtitle>
        </Row>

        <Row image={RoadmapI60} side='right'>
            <Progress 
                amount={60} 
                complete={true}
                color={'#ce5169'}
                date={'May 2022'}
                status='Ongoing' />
            <Title>
                Announce Full Line-Up
            </Title>
            <Subtitle>
                We will be looking to time-honored theatre groups like "Second City" And "Groundlings" to case for our show's comedic voice and writing talent. We expect, as the show matures, to cast more recognizable actors and comedians.
            </Subtitle>
        </Row>

        <Row image={RoadmapI70} side='left'>
            <Progress 
                amount={70} 
                complete={true}
                color={'#ce7d51'}
                date={'May 2022'}
                status='Completed' />
            <Title>
                Web3 Animation Studio
            </Title>
            <Subtitle>
                Seek out ceatives to form a new web3 animation studio. The NFT world moves fast, so we want to find forward-thinking entrepreneurs who understand the power and potential of web3.
            </Subtitle>
        </Row>

        <Row image={RoadmapI80} side='right'>
            <Progress 
                amount={80} 
                complete={true}
                color={'#cec451'}
                date={'June 2022'}
                status='Completed' />
            <Title>
                Pilot Episode Fuzzy Felons
            </Title>
            <Subtitle>
                Release the pilot episode of Fuzzy Felons featuring Felons 'cast' from our community by our community, with royalties paid out accordingly. Over $6000 paid out in royalties so far!
                <Spacer size='sm'/>
                Recruitment drive for artists, musicians, and animators.
            </Subtitle>
        </Row>

        <Row image={RoadmapI90} side='left'>
            <Progress 
                amount={90} 
                complete={true}
                color={'#66ce51'}
                date={'June 2022'}
                status='Completed' />
            <Title>
                Community Driven Animation
            </Title>
            <Subtitle>
                Official commencement of ColourBleed Studio, for developing and creating content for Web3 ideology.
            </Subtitle>
        </Row>

        <Row image={RoadmapI100} side='right'>
            <Progress 
                amount={100} 
                complete={true}
                color={'#51ce95'}
                date={'June 2022'}
                status='Completed' />
            <Title>
                Mint Studio Meta Pass
            </Title>
            <Subtitle>
                Used to enter Phase 2, which features "Fuzzy Friends" collection. Fuzzy Felons holders receive free airdrop of Meta Pass.
            </Subtitle>
        </Row>

    </Content>
  );
};

export default Roadmap;

const Content = styled.div `
    width: 100%;
`

const Title = styled.div `
    font-weight: bold;
    margin-bottom: 15px;
`
const Subtitle = styled.div `
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 15px;
`

interface ProgressProps {
    amount: number,
    color: string,
    complete?: boolean,
    date?: string,
    status?: string | null
}

const Progress: React.FC<ProgressProps> = ({ 
    amount,
    color,
    date='',
    complete=false,
    status=null

}) => {
    return (
    <ProgressStyle color={color}>
        {/* {complete && 
            <div className='done'>
                <FontAwesomeIcon icon={faCheck} size='2x' />
            </div> 
        } */}
        <div className='percent'>
            {complete && 
                <div className='left'>              
                    <FontAwesomeIcon icon={faCheckCircle} size='2x' />
                </div> 
            }
            <div className='right'>
                <div className='amt'>
                    {amount}%
                </div>
                { date != '' &&
                    <div className='date'>{date}</div>
                }
                { status && 
                <div className='status'>
                    Status: <span>{ status }</span>
                </div>
                }
            </div>      
        </div>
        
    </ProgressStyle>
    );
};

interface ProgressStyleProps {
    color: string
}

//const TextShadow = "text-shadow: 10px 10px 25px rgba(0, 0, 0, 0.3)"
const TextShadow = "text-shadow: 10px 10px 25px rgba(0, 0, 0, 0.3)"

const ProgressStyle = styled.div<ProgressStyleProps> `
    margin-bottom: 15px;
    position: relative;

    .amt { 
        position: relative;
        color: #01d388;
        ${TextShadow};
    }

    .status {
        margin-top: 5px;
        font-size: 14px;
        text-transform: uppercase;
        span {
            font-weight: bold;
        }
    }

    .percent {
        display: flex;
        margin-bottom: 5px;
        //color: ${props => props.color};
        //color: #2bf1d8;        

        .left {
            width: 60px;
            margin-right: 10px;
            svg {
                filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2));
            }
        }
        .right {
            flex: 1;
            position: relative;

            .amt {
                font-size: 60px;
                font-weight: bold;
                font-family: 'Titan One', sans-serif;
            }
        }
    }
    .date {
        text-transform: uppercase;
        font-size: 20px;
    }
`




interface RowProps {
    side: string,
    image?: string,
    position?: string,
}

const Row: React.FC<RowProps> = ({ 
    children, 
    side,
    image,
    position='center'
}) => {
    return (  
    <RowContent>
        <div className='text'>
            <GlassBorder />
            { side === 'left' ? (
                <Fade left>
                    <div>
                        { children }
                    </div>
                </Fade>
            ):(
                <Fade right>
                    <div>
                        { children }
                    </div>
                </Fade>
            )}
        </div>
        <div className='p'>
            <BoxShadow />
            <Parallax speed={-20}>
                <div className='i' style={{backgroundImage: "url(" + image + ")", backgroundPosition: 'top'}}></div>
            </Parallax>
        </div>        
    </RowContent>
    );
};

const GlassBorder = styled.div `
    position: absolute;
    left: 0px;
    top: -100px;
    right: 0px;
    height: 1px;
    z-index: 5;
    background: rgba(255, 255, 255, .3);
`

const BoxShadow = styled.div `
    -moz-box-shadow:    inset 0 0 10px rgba(0, 0, 0, .7);
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, .7);
    box-shadow:         inset 0 0 10px rgba(0, 0, 0, .7);
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 5;
`


const RowContent = styled.div `
    display: flex;
    width: 100%;
    position: relative;

    //background: ${BackgroundColor};

    > div {
        overflow: hidden;
        height: ${RowHeight}px;
        width: 50%;
        img {
            max-width: 100%;
        }
    }

    &:nth-child(even) {
        flex-direction: row;
        div.text {
            text-align: right;
        }
    }
    
    &:nth-child(odd) {
        flex-direction: row-reverse;
        .percent {
            flex-direction: row-reverse;
        }
    }

    div.text {
        // background: #111;
        // mix-blend-mode: screen;
        position: relative;
        background-color: rgba(255, 255, 255, 0.1);
        font-size: 28px;
        > div {
            padding: 50px;
        }
    }

    div.p {
        position: relative;
    }

    div.i {
        position: relative;
        top: -100px;
        height: ${ImageHeight}px;
        background-size: cover;
    }

    ${MobileStyles(`
        display: block;
        > div {
            width: auto;
        }
        .text {
            height: auto;
        }
        .p {
            height: 300px;
        }
    `)};
`