import React, { useState } from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';


interface Props {  
  min?: number | null,
  max?: number | null,
  value?: number,
  name?: string
}

const NumberPicker: React.FC<Props> = ({ 
  min =0, 
  max = null,
  value = 1,
  name = 'count'
}) => {
    const [count, setCount] = useState<number>(value);

    const Add = () => {
        if ( max !== null && count >= max ) {
            setCount(max)
            return
        }
        setCount(count+1)
    }

    const Subtract = () => {
        if ( min !== null && count <= min ) {
            setCount(min)
            return
        }
        setCount(count - 1)
    }

    return (
        <Picker>
            <Row>
                <Left onClick={Subtract}>
                    <FontAwesomeIcon icon={faMinusCircle} size='2x' />
                </Left>
                <Center>
                    <Input>
                        <input name={name} type='text' value={count} readOnly />
                    </Input>
                </Center>
                <Right onClick={Add}>
                    <FontAwesomeIcon icon={faPlusCircle} size='2x'/>
                </Right>
            </Row>
        </Picker>     
    )
}


const Picker = styled.div `
    width: 135px;
    margin: 0px auto;

    svg {
        cursor: pointer;
        transition: color 0.2s ease;
        color: rgba(255, 255, 255, 0.6);
        :hover {
            color: rgba(255, 255, 255, 0.9);
        }
    }
`

const Row = styled.div `
    display: flex;
    justify-contents: center;
    align-items: center;
`

const Center = styled.div `
    flex: 1;
    padding: 0px 10px;
`

const Input = styled.div `
    padding: 6px;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    
    //backdrop-filter: blur(5px); 
    background-color: rgba(255, 255, 255, 0.2);
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    input {
        color: ${props => props.theme.text.primary.color};
        border: none;
        font-size: 20px;
        background: none;
        width: 100%;
        height: 40px;
        text-align: center;
    }
`

const Right = styled.div `
    text-align: left;
`
const Left = styled.div `
    text-align: right;
`

export default NumberPicker