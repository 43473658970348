export declare enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42
}

export type ConfigurationProps = {
  version: number
  chainId: number
  refreshInterval: number
}

const configurations: { [env: string]: ConfigurationProps } = {
  development: {
    version: 1,
    chainId: 1,
    refreshInterval: 120000,
  },
  production: {
    version: 1,
    chainId: 1,
    refreshInterval: 120000,
  },
};

export default configurations[process.env.NODE_ENV || "development"];
