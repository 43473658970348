import React from 'react'
import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

//interface StyledLinkProps extends HTMLAnchorElement {  
interface StyledLinkProps {  
  to?: string,
  href?: string,
  rel?: string,
  text?: string,
  className?: string,
  activeClassName?: string | null
}

const StyledLink: React.FC<StyledLinkProps> = ({ 
  children, 
  text,
  to,
  href,
  rel='nofollow',
  className,
  activeClassName=null
}) => {
  return (
    <>
    { to && !activeClassName &&
      <InternalLink to={to} className={className} >
        {text}
        {children}
      </InternalLink>       
    }
    { to && activeClassName &&
      <InternalNavLink to={to} className={className} >
        {text}
        {children}
      </InternalNavLink>       
    } 
    { href && 
      <ExternalLink href={href} target='_blank' rel={rel} className={className}>
        {text}
        {children}
      </ExternalLink>       
    } 
    </>     
  )
}


const InternalLink = styled(Link)` 
  color: ${props => props.theme.text.primary.color};
`

const InternalNavLink = styled(NavLink)` 
  color: ${props => props.theme.text.primary.color};
`

const ExternalLink = styled.a` 
  color: ${props => props.theme.text.primary.color};
`

export default StyledLink