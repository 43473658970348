import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import SectionHeader from './SectionHeader';
import {Collapse} from 'react-collapse';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronRight, faChevronUp, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import Spacer from '../../../components/Spacer';
import { MobileStyles } from '../../../contexts/Theme/Mixins'


const Fade = require('react-reveal/Fade');


const Faq: React.FC = ({  }) => {

  return (  
    <Content id='faq'>
        <Fade bottom>
            <SectionHeader title='FAQ' subtitle='Know your rights' />
        </Fade>

            <Spacer size='lg' />
            <Collapsers>
                <Fade bottom>
                    <Collapser title='Can I see the Smart Contract?'>
                        <SmartContract>
                            <div>Here is the Smart Contract address</div>
                            <br/>
                            <div className='contractAddress'>
                                0x5759110402AA65241FD03435646aFD01a91Ab948
                            </div>
                            <Spacer size='md' />
                            <a href='https://etherscan.io/address/0x5759110402AA65241FD03435646aFD01a91Ab948#code' target='_blank'>
                                View Smart Contract on Etherscan <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                        </SmartContract>
                    </Collapser>
                </Fade>

                <Fade bottom>
                    <Collapser title='What is the mint price?'>
                        0.1 ETH
                    </Collapser>
                </Fade>

                <Fade bottom>
                    <Collapser title='How many can I mint?'>
                        All WL holders can mint 1 Felon during the first 24 hours. During the second 24 hours, up to 3 additional Felons, can be minted from the remainder of unclaimed WL spots. If any Felons remain available after the second 24 hours, they will become available for a public mint.
                    </Collapser>
                </Fade>

                <Fade bottom>
                    <Collapser title='What are the royalties?'>
                        7.5% of the secondary sales of Fuzzy Felons will go to the collection creators. 
                    </Collapser>
                </Fade>
            </Collapsers>
        
    </Content>
  );
};

export default Faq;

const SmartContract = styled.div `
    
    

    ${MobileStyles(`
        .contractAddress {
            font-size: 13px;
        }
    `)};

    a {
        color: #fff;
        opacity: .9;
        &:hover {
            opacity: 1;
        }
    }
`

const Content = styled.div `
    width: 100%;

    ${MobileStyles(`
        width: auto;
        padding: 0px 20px;
    `)};
`

const Collapsers = styled.div `
    max-width: 800px;
    margin: 0px auto;
    > div {
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    }
`


// =============================================================
//   Collapser
// =============================================================
interface CollapserProps {
    title: string
}

const Collapser: React.FC<CollapserProps> = ({  children, title  }) => {
    const [isOpened, setIsOpened] = useState<boolean>(true)

    return (  
        <CollapserContent>
            <Bar onClick={() => { setIsOpened(!isOpened) }}>
                <LBar>
                    { title }
                </LBar>
                <RBar>
                    { isOpened ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                    ):(
                        <FontAwesomeIcon icon={faChevronDown} />
                    )}
                </RBar>
            </Bar>
            <Collapse isOpened={isOpened}>
                <CollapseContent>
                    {children}
                </CollapseContent>
            </Collapse>
        </CollapserContent>
    );
};

const CollapserContent = styled.div `

`
const Bar = styled.div `
    padding: 20px 5px;
    cursor: pointer;
    display: flex;
    font-size: 24px;
`

const LBar = styled.div `
    flex: 1;
`

const RBar = styled.div `
    width: 50px;
`

const CollapseContent = styled.div `
    line-height: 145%;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
    padding: 0px 5px 20px 5px;

    ul {
        padding: 0px 20px;
    }
    li {
        margin-bottom: 15px;
    }

`