import React from 'react'
import styled from 'styled-components'

interface ModalActionProps {
  children?: React.ReactNode
}

const ModalActions: React.FC<ModalActionProps> = ({ children }) => {
  const l = React.Children.toArray(children).length
  return (
    <StyledModalActions>
      {React.Children.map(children, (child, i) => (
        <>
          <StyledModalAction>
            {child}
          </StyledModalAction>
          {i < l - 1 && <StyledSpacer />}
        </>
      ))}
    </StyledModalActions>
  )
}

 
const StyledModalActions = styled.div`
  padding: 0 ${props => props.theme.spacing[4]}px;
  align-items: center;
  justify-content: right;
  display: flex;
  height: 96px;
  margin: ${props => props.theme.spacing[4]}px ${props => -props.theme.spacing[4]}px ${props => -props.theme.spacing[4]}px;
`

const StyledModalAction = styled.div`
  
`

const StyledSpacer = styled.div`
  width: ${props => props.theme.spacing[3]}px;
  height: ${props => props.theme.spacing[2]}px;
`

export default ModalActions