import  Web3 from 'web3';
import config from '../../config';

const WhitelistJson = require("../../contracts/whitelist/whitelist.json")
const ContractJson = require("../../contracts/abis/FuzzyFelonsCollection.json")
const chainId = config.chainId
const Token = ContractJson.networks[chainId]

export class FuzzyContract {
    web3: any;
    contract: any;

    constructor(provider:any) {
        if ( !provider ) return

        this.web3 = new Web3(provider)
        this.contract = new this.web3.eth.Contract(
            ContractJson.abi, 
            Token.address,
        )
        //this.contract.options.gas = 4500000
        //this.contract.options.gasPrice = '10000000000'
    }

    async getMintPrice(numTokens:number) {
        const mintPrice:string = await this.contract.methods.MINT_PRICE().call()
        return Web3.utils.toBN(mintPrice) as any * numTokens
    }

    async getPhase() {
        return await this.contract.methods.phase().call()
    }

    async getTotalSupply() {
        return await this.contract.methods.totalSupply().call()
    }

    async getBalance(address:string) {
        return await this.contract.methods.balanceOf(address).call()
    }

    async getPaused() {
        return await this.contract.methods.paused().call()
    }

    async getMaxMintableTokens(address:string) {
        const phase = await this.getPhase()
        if (phase == 0 || phase == 4) {
            // Minting not active
            return 0;
        } else if (phase == 1) {
            const alreadyMinted = await this.contract.methods.mintCount(address).call()
            return alreadyMinted > 0 ? 0: 1
        } else if (phase == 2) {
            const alreadyMinted = await this.contract.methods.mintCount(address).call()
            const maxAllowed = await this.contract.methods.MAX_WL_MINT().call()
            return Math.max(maxAllowed - alreadyMinted, 0)
        } else if (phase == 3) {
            const balance = await this.contract.methods.balanceOf(address).call()
            const maxAllowed = await this.contract.methods.MAX_PUBLIC_MINT().call()
            return Math.max(maxAllowed - balance, 0)
        }
        return 0
    }

    async getRevertReason(txHash:string) {
        const tx:any = await this.web3.eth.getTransaction(txHash)
        try {
            var result:any = await this.web3.eth.call(tx)
        } catch (error:any) {
            // TODO: This is ghetto, there must be a better way.
            var msg = error.message
            msg = msg.replace(/ /g,'') // remove spaces
            msg = msg.replace(/(\r\n|\n|\r)/gm, '') // remove line breaks
            const reason = msg.substring(
                msg.indexOf(':') + 1,
                msg.indexOf('{'),
            )
            return reason
        }
        return null
    }
}

// Show the transaction on etherscan
export function getTxEtherscanUrl(txHash:string):string {
    if ( config.chainId == 1 ) {
        return "https://etherscan.io/tx/" + txHash
    }
    return "https://rinkeby.etherscan.io/tx/" + txHash
}

// Get the Opensea link to show the tokens of the address
export function getOpenseaUrl(address:string) {
    if ( config.chainId == 1 ) {
        return "https://opensea.io/" + address + "?search[sortBy]=CREATED_DATE&search[sortAscending]=false";
    }
    return "https://testnets.opensea.io/" + address + "?search[sortBy]=CREATED_DATE&search[sortAscending]=false";
}

// The whitelist. In production this will be a relatively large list (a couple
// thousand entries). This should probably be saved in a json file on the server
// that is downloaded by your code.
export function getWhiteList() {
    return WhitelistJson.whitelist.map((account:string) => {return account.toLowerCase()})
    return WhitelistJson.whitelist
}